/* thead, tbody { display: block !important; } */
/* .chartjs-render-monitor{
  width: 100% !important; */
/* } */

button.button-primary-helper:disabled{
  opacity:50% !important;
}
.custom-header-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 1px;
    height: 1.6em;
    background-color: white !important;
    transform: translateY(-50%);
    transition: background-color 0.3s ease 0s;
    content: "";
}
.custom-header-table .ant-table-thead > tr > th {
  background-color: #004AAD !important; /* Ganti dengan warna latar belakang yang Anda inginkan */
  color: white!important; /* Ganti dengan warna teks yang Anda inginkan */
}
.card-chart-dashboard{
  margin-bottom: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
}
.legend1-card-chart-dashboard{
  height: 15px;
  width: 15px;
  background-color: #F8D2D2;
  border-radius: 100%;
}
.legend2-card-chart-dashboard{
  height: 15px;
  width: 15px;
  background-color: #2B69BD;
  border-radius: 100%;
}

.desc1-legend-card-chart-dashboard{
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.desc2-legend-card-chart-dashboard{
  color: #2B69BD;
  font-weight: bold;
  margin-left: 10px;
}

.card-counter-dashboard{
  border-radius: 10px;
  background-color: white;
  text-align:center;
  padding:25px;
  box-shadow:0px 0px 2px 0px #959595;
}

.title-card-counter-dashboard{
  font-size: 16px;
}
.desc-card-counter-dashboard{
  font-size: 38px;
  color: #004AAD;
  font-weight:bold
}



.ant-image-preview-img-wrapper{
  zoom:150% !important;
}
.ant-image-preview-img{
  height: 400px !important; width: 400px !important;
}
.ck-file-dialog-button{
  display: none !important;

}
.ck-dropdown__button{
  display: none !important;
}
picture>img{
  width: 100%!important;
}
.ck-editor__main{
}
.ck-editor{
  border-radius: 0.625rem !important;
  border:1px solid rgb(230, 235, 241) !important;
}
.ck-toolbar{
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
  border:1px solid rgb(230, 235, 241) !important;

  /*border:none !important;*/
}
.ck-content{
  border-bottom-left-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
  height: 200px!important;
  border:1px solid rgb(230, 235, 241) !important;

}
.ant-empty-image{
  margin-top: 10px !important;
}
table.custom-table {
  overflow-x: auto !important;
  display: block !important;
}
.notAllowed{
  cursor: not-allowed !important;
}
.ant-skeleton-title{
  width: 100% !important;
}
td.loading-skeleton{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
}

.ant-skeleton{
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  padding-left: 1px !important;
  padding-right: 1px !important;
}

tbody.custom-body {
    width: 100%;
    overflow-y: auto !important;    /* Trigger vertical scroll    */
    overflow-x: hidden !important;  /* Hide the horizontal scroll */
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph{
  margin-top: 0 !important;

}
.ant-pagination-item, .ant-pagination-total-text .ant-pagination-item{
  padding: 5px !important;
  line-height: normal !important;
}
.ant-pagination-item, .ant-pagination-total-text{
  height: auto !important;
}
.ant-skeleton-content .ant-skeleton-title{
  height: 25px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  vertical-align: middle !important;

}
.ant-pagination-item-active>.custom-page{
  color:white !important;
}
.ant-pagination-item-active{
  background-color: #18509E !important;
  font-weight: normal !important;
}
.ant-pagination-item{
  color: black !important;
}
.middle {
  vertical-align: middle !important;
}
.ant-checkbox{
  top: 0 !important;
}

.ant-select-selection-item-content{
  font-size: 12px !important;
}
.ant-select-multiple .ant-select-selector{
  padding:0px 8.5px;
}

.section-head-order-delivery{
  padding-top: 12px!important;
  padding-left: 12px!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
}

button.ant-custom:focus{
  color:white!important;
}
button.ant-custom:hover{
  color:white!important;
}

.my-tag-delivery{
  background-color: #EB2935;
  border-color: transparent!important;
  color:white;
  border-radius: 4px!important;
}
.my-section-order-delivery{
  padding-left: 12px!important;
  padding-right: 12px!important;
  padding-bottom: 0!important;
}
.section-name-order-delivery{
  background-color: transparent!important;
  box-shadow: none!important;
  border: none!important;
  padding:0!important;
  color: black!important;
  font-weight: bold!important;
}
.btn-order-delivery{
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
  border: none!important;
  color:#18509E!important;
  background-color:#E8F4EE!important;
  font-weight: bold!important;

}
.txtRight{
  text-align: right!important;
}
.txtCenter{
  text-align: center!important;
}
/* .ant-input{
  padding: 6px 11px !important;
}
.ant-btn{
  padding: 6px 11px !important;
  height: auto !important;
} */
.ant-table table{
  background-color: transparent !important;
  box-shadow: none !important;
}

/* .ant-input-affix-wrapper{
  padding: 0px 7px 0px 0px !important;
}

.ant-select-multiple .ant-select-selector{
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    padding: 0px 11px !important;
} */

.ant-input-group-addon{
  background-color: white!important;
}
.ant-timerange{
  border-radius: 0!important;
  border-right: none!important;
  border-top-left-radius: 0.625rem!important;
  border-bottom-left-radius: 0.625rem!important;
}

.react-timerange-picker__inputGroup{
  flex-grow:0!important;
  min-width: 0!important;
}
.react-timerange-picker__clock{
  border: 1px solid #18509E!important;
  border-radius: 0.625rem!important;
}
.ant-input-disabled, .ant-input[disabled]{
  color: black!important;
}
.react-timerange-picker__clear-button{
  display: none!important;
}
.react-timerange-picker__clock-button{
    display: none!important;
    /* right: 0!important; */
    /* text-align: right!important; */
}
.react-timerange-picker__wrapper{
  border: none!important;
}
.react-timerange-picker__range-divider{
  /* display: none; */
  text-align: center!important;
  margin-left: 10px!important;
  margin-right: 10px!important;

}
.ant-form-item-label>label{
  font-size: 12px!important;
  color: black!important;
  font-weight: bolder!important;
}

.noborder-radius{
  border-radius: 0!important;
}
.bold{
  font-weight: bold;
}
.ant-select-selection-placeholder{
  /* color:red !important; */
  margin-top: 0px !important;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue{
  background-color: transparent !important;
}
.ant-picker, .ant-picker-input > input{
  padding: 8.5 11px;
  font-weight: normal !important;
  color: #525358 !important;
  font-size: 12px !important;
}
.nowrap {
  white-space: nowrap !important;
}

.ant-table-tbody > tr > th{
  white-space: nowrap !important;
  font-weight: bold !important;
  color:#2C2D30 !important;

}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid rgb(230, 235, 241);
  transition: background 0.3s ease 0s;
  color:#525358 !important;
}

.ant-select-multiple .ant-select-selection-item{
  background-color: #3F4254 !important;
  color:white !important;
  border-color:#3F4254 !important;
  font-weight:400;
}
.ant-select-multiple .ant-select-selection-item-remove{
  color: white !important;
}
.ant-form-item-required{
  /* font-size:13px !important; */
  font-weight:500 !important;
  font-size: 14px !important;
  line-height: 1.5;
}
.ant-modal-title{
  font-weight:bold;
}

.ant-form-item-explain-error{
  font-weight: bold!important;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #18509E;
}
h1{
  font-size: 20px;
}

.ant-card-head {
  background: #f7f7f7;
  border: 1px solid rgba(221, 223, 226, 0.75);
  font-weight: 600;
}

.ant-table table {
  background: #ffffff;
  border: 1px solid rgba(221, 223, 226, 0.75);
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(82, 63, 105, 0.05);
  /* border-radius: 6px; */
}
html {
  background-color: #ededed;
}
.ant-layout {
  background: #f6f6f6;
}
.ant-layout-sider-children{
  padding-top: 10px;
  padding-bottom: 20px;
}
.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  border-radius: 20%;
  background-color: #f3f6f9;
  color: #f3f6f9;
}
.ant-pagination-item-active a {
  border-radius: 20%;

  background-color: #18509E;
}

.chartjs-render-monitor{
  width: 100% !important;
    /* font-family: 'Signika Negative', sans-serif !important; */
}
*{
  /* font-family: 'Signika Negative', sans-serif !important; */
}
#msg{
  margin-bottom: 20px;
}


.ant-menu-title-content {
  font-weight: 600;
}

h1 {
  font-weight: 600;
}

.ant-table table {
  border: none;
}

.ant-table-fixed-header .ant-table-thead > tr > th {
  background: white;
}
.ant-upload.ant-upload-select {
  width: 100%;
}
.ant-input-number {
  width: 100%;
}
.custom-card-trx{
    transition: all .3s ease-in-out;

}
.custom-card-trx:hover{
  /* background-color: #E8F4EE !important; */
  transform: translate(-10px);
  /* box-shadow: #E8F4EE 0px 19px 43px; */
  transform: translate3d(0px, -1px, 0px);
}
@media only screen and (max-width: 1400px) {
.ant-card-type-inner .ant-card-head-title {
    font-size: 12px !important;
  }

  p {
    font-size: 11px;
  }

  a {
    font-size: 12px !important;
  }

  .ant-table {
    font-size: 12px !important;
  }

  .ant-card {
    font-size: 11px !important;
  }

  .ant-table-cell {
    font-size: 12px;
  }

  tspan {
    font-size: 10px;
  }
    .ant-avatar.ant-avatar-icon{
      /* top: 18px; */
    }
    label{
      font-size: 10px!important;
    }
    .ant-select{
      /* font-size: 12px; */
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      height: 35px!important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 35px;

    }
        .ant-form-item {
          font-size: 12px;
        }
}

/* CUSTOM DASHBOARD LOGIN */
.dashboard-login-custom-image-text-top{
  text-align: center;
  margin-top: 10%;
}
.dashboard-login-custom-image-text-after-image{
  text-align: center;
  margin-top: 2%;
}
.dashboard-login-custom-title{
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 600;
  color: white;
}
.dashboard-login-custom-paragraph{
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 200;
  color: white;
}
.dashboard-login-custom{
  background:linear-gradient(359.45deg, #004AAD 0.93%, #001E47 99.98%);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 1000;
  overflow: hidden;
}
.dashboard-login-custom-image {
  display: block; 
  width: 100%; 
  height: auto;
  object-fit: contain;
}
.dashboard-login-custom-grid{
  background: #000F23;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: white;
  font-family: 'Inter';
  font-size: 21px;
  cursor: pointer;
}


/* CUSTOM DASHBOARD LOGIN */

